import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const dialogDefaultVariants = {
  "size": "standard",
  "variant": "default"
}
const dialogCompoundVariants = []

const dialogSlotNames = [
  [
    "root",
    "dialog__root"
  ],
  [
    "trigger",
    "dialog__trigger"
  ],
  [
    "portal",
    "dialog__portal"
  ],
  [
    "overlay",
    "dialog__overlay"
  ],
  [
    "closeButton",
    "dialog__closeButton"
  ],
  [
    "content",
    "dialog__content"
  ],
  [
    "container",
    "dialog__container"
  ],
  [
    "header",
    "dialog__header"
  ],
  [
    "footer",
    "dialog__footer"
  ],
  [
    "title",
    "dialog__title"
  ],
  [
    "description",
    "dialog__description"
  ],
  [
    "body",
    "dialog__body"
  ]
]
const dialogSlotFns = /* @__PURE__ */ dialogSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, dialogDefaultVariants, getSlotCompoundVariant(dialogCompoundVariants, slotName))])

const dialogFn = memo((props = {}) => {
  return Object.fromEntries(dialogSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const dialogVariantKeys = [
  "centered",
  "scrollBehavior",
  "size",
  "variant"
]
const getVariantProps = (variants) => ({ ...dialogDefaultVariants, ...compact(variants) })

export const dialog = /* @__PURE__ */ Object.assign(dialogFn, {
  __recipe__: false,
  __name__: 'dialog',
  raw: (props) => props,
  variantKeys: dialogVariantKeys,
  variantMap: {
  "centered": [
    "true",
    "false"
  ],
  "scrollBehavior": [
    "inside",
    "outside"
  ],
  "size": [
    "standard",
    "fullscreen"
  ],
  "variant": [
    "default",
    "lightbox",
    "modal"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, dialogVariantKeys)
  },
  getVariantProps
})
import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const tripCardDefaultVariants = {
  "variant": "overlay"
}
const tripCardCompoundVariants = []

const tripCardSlotNames = [
  [
    "root",
    "tripCard__root"
  ],
  [
    "content",
    "tripCard__content"
  ],
  [
    "image",
    "tripCard__image"
  ],
  [
    "footer",
    "tripCard__footer"
  ],
  [
    "tag",
    "tripCard__tag"
  ],
  [
    "duration",
    "tripCard__duration"
  ],
  [
    "description",
    "tripCard__description"
  ],
  [
    "title",
    "tripCard__title"
  ],
  [
    "footerContent",
    "tripCard__footerContent"
  ],
  [
    "priceContainer",
    "tripCard__priceContainer"
  ],
  [
    "button",
    "tripCard__button"
  ],
  [
    "priceLabel",
    "tripCard__priceLabel"
  ],
  [
    "priceValue",
    "tripCard__priceValue"
  ],
  [
    "priceOriginal",
    "tripCard__priceOriginal"
  ],
  [
    "promo",
    "tripCard__promo"
  ],
  [
    "interestLink",
    "tripCard__interestLink"
  ]
]
const tripCardSlotFns = /* @__PURE__ */ tripCardSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, tripCardDefaultVariants, getSlotCompoundVariant(tripCardCompoundVariants, slotName))])

const tripCardFn = memo((props = {}) => {
  return Object.fromEntries(tripCardSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const tripCardVariantKeys = [
  "variant"
]
const getVariantProps = (variants) => ({ ...tripCardDefaultVariants, ...compact(variants) })

export const tripCard = /* @__PURE__ */ Object.assign(tripCardFn, {
  __recipe__: false,
  __name__: 'tripCard',
  raw: (props) => props,
  variantKeys: tripCardVariantKeys,
  variantMap: {
  "variant": [
    "overlay",
    "stacked"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, tripCardVariantKeys)
  },
  getVariantProps
})
import NextLink from 'next/link'
import { forwardRef } from 'react'

/**
 * A React component that extends the HTML `<a>` element to provide [prefetching](https://nextjs.org/docs/app/building-your-application/routing/linking-and-navigating#2-prefetching)
 * and client-side navigation between routes.
 *
 * It is the primary way to navigate between routes in Next.js.
 *
 * Read more: [Next.js docs: `<Link>`](https://nextjs.org/docs/app/api-reference/components/link)
 */
const Link = forwardRef<
  HTMLAnchorElement,
  React.ComponentProps<typeof NextLink>
>((props, ref) => {
  return (
    <NextLink {...props} ref={ref} prefetch={props.prefetch ?? false}>
      {props.children}
    </NextLink>
  )
})

Link.displayName = 'Link'

export default Link

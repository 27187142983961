import { css, cx, type Styles } from '@/styled-system/css'

import { button as buttonRecipe } from '@/styled-system/recipes'

import type { RecipeVariantProps } from '@/styled-system/types'

type ButtonVariantProps = RecipeVariantProps<typeof buttonRecipe>

type ButtonIconProps = {
  rightIcon?: React.ReactNode
  leftIcon?: React.ReactNode
  icon?: React.ReactNode
}

type As = React.ElementType

export type ButtonProps<T extends As = 'button'> = React.ComponentProps<T> &
  ButtonVariantProps &
  ButtonIconProps & { css?: Styles }

const ButtonIcon = ({
  icon,
  css: cssProps,
}: {
  icon?: React.ReactNode
  css?: Styles
}) => {
  const className = cx('button-icon', css(cssProps))
  return icon ? <span className={className}>{icon}</span> : null
}

const ButtonContent = ({
  leftIcon,
  rightIcon,
  icon,
  children,
}: ButtonIconProps & { children: React.ReactNode }) => {
  return (
    <>
      <ButtonIcon icon={leftIcon} css={{ mr: 'semantic-spacing-8' }} />
      <ButtonIcon icon={icon} css={{ mx: 0 }} />
      {children}
      <ButtonIcon icon={rightIcon} css={{ ml: 'semantic-spacing-8' }} />
    </>
  )
}

/**
 * @example
 * ```jsx
 * import { Button } from '@/components/button';
 *  You can use the `Button` component to create a button with different variants and sizes.
 *  Or even pass as "a" link by using the `as` prop.
 * export const ExampleButton = () => {
 *   return <Button variant="primary" size="sm">Click me</Button>;
 * };
 * ```
 */
export const Button = <T extends As = 'button'>(
  props: ButtonProps<T> & { as?: T },
) => {
  const [variantProps, localProps] = buttonRecipe.splitVariantProps(props)

  const {
    as: componentAs = 'button',
    className: classNames,
    css: cssProps,
    icon,
    leftIcon,
    rightIcon,
    children,
    shape,
    ...rest
  } = localProps

  const Component = componentAs as React.ElementType

  const iconButtonClass = icon ? 'icon-button' : ''
  const linkButtonClass =
    shape === 'link'
      ? css({
          px: 0,
        })
      : ''

  const className = cx(
    buttonRecipe(variantProps),
    css(cssProps),
    iconButtonClass,
    linkButtonClass,
    classNames,
  )

  return (
    <Component className={className} {...rest}>
      <ButtonContent leftIcon={leftIcon} rightIcon={rightIcon} icon={icon}>
        {children}
      </ButtonContent>
    </Component>
  )
}

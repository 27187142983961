import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const titleFn = /* @__PURE__ */ createRecipe('heading', {
  "size": "heading3",
  "theme": "dark",
  "format": "logotype"
}, [
  {
    "format": "standard",
    "size": "heading1",
    "css": {
      "base": {
        "textStyle": "semantic-heading-standard-xl"
      },
      "sm": {
        "textStyle": "semantic-heading-standard-3xl"
      },
      "md": {
        "textStyle": "semantic-heading-standard-4xl"
      }
    }
  },
  {
    "format": "standard",
    "size": "heading2",
    "css": {
      "base": {
        "textStyle": "semantic-heading-standard-xl"
      },
      "lg": {
        "textStyle": "semantic-heading-standard-3xl"
      },
      "md": {
        "textStyle": "semantic-heading-standard-2xl"
      }
    }
  },
  {
    "format": "standard",
    "size": "heading3",
    "css": {
      "base": {
        "textStyle": "semantic-heading-standard-lg"
      },
      "lg": {
        "textStyle": "semantic-heading-standard-2xl"
      },
      "md": {
        "textStyle": "semantic-heading-standard-xl"
      }
    }
  },
  {
    "format": "standard",
    "size": "heading4",
    "css": {
      "base": {
        "textStyle": "semantic-heading-standard-md"
      },
      "md": {
        "textStyle": "semantic-heading-standard-lg"
      },
      "lg": {
        "textStyle": "semantic-heading-standard-xl"
      }
    }
  },
  {
    "format": "standard",
    "size": "heading5",
    "css": {
      "base": {
        "textStyle": "semantic-heading-standard-sm"
      },
      "md": {
        "textStyle": "semantic-heading-standard-lg"
      }
    }
  },
  {
    "format": "standard",
    "size": "heading6",
    "css": {
      "base": {
        "textStyle": "semantic-heading-standard-xs"
      },
      "md": {
        "textStyle": "semantic-heading-standard-md"
      }
    }
  },
  {
    "format": "logotype",
    "size": "heading1",
    "css": {
      "base": {
        "textStyle": "semantic-heading-logotype-xl"
      },
      "sm": {
        "textStyle": "semantic-heading-logotype-3xl"
      },
      "md": {
        "textStyle": "semantic-heading-logotype-4xl"
      }
    }
  },
  {
    "format": "logotype",
    "size": "heading2",
    "css": {
      "base": {
        "textStyle": "semantic-heading-logotype-xl"
      },
      "lg": {
        "textStyle": "semantic-heading-logotype-3xl"
      },
      "md": {
        "textStyle": "semantic-heading-logotype-2xl"
      }
    }
  },
  {
    "format": "logotype",
    "size": "heading3",
    "css": {
      "base": {
        "textStyle": "semantic-heading-logotype-lg"
      },
      "lg": {
        "textStyle": "semantic-heading-logotype-2xl"
      },
      "md": {
        "textStyle": "semantic-heading-logotype-xl"
      }
    }
  },
  {
    "format": "logotype",
    "size": "heading4",
    "css": {
      "base": {
        "textStyle": "semantic-heading-logotype-md"
      },
      "md": {
        "textStyle": "semantic-heading-logotype-lg"
      },
      "lg": {
        "textStyle": "semantic-heading-logotype-xl"
      }
    }
  },
  {
    "format": "logotype",
    "size": "heading5",
    "css": {
      "base": {
        "textStyle": "semantic-heading-logotype-sm"
      },
      "md": {
        "textStyle": "semantic-heading-logotype-lg"
      }
    }
  },
  {
    "format": "logotype",
    "size": "heading6",
    "css": {
      "base": {
        "textStyle": "semantic-heading-logotype-xs"
      },
      "md": {
        "textStyle": "semantic-heading-logotype-md"
      }
    }
  },
  {
    "format": "pullout",
    "size": "heading1",
    "css": {
      "base": {
        "textStyle": "semantic-heading-pullout-xl"
      },
      "md": {
        "textStyle": "semantic-heading-pullout-4xl"
      },
      "sm": {
        "textStyle": "semantic-heading-pullout-3xl"
      }
    }
  },
  {
    "format": "pullout",
    "size": "heading2",
    "css": {
      "base": {
        "textStyle": "semantic-heading-pullout-xl"
      },
      "lg": {
        "textStyle": "semantic-heading-pullout-3xl"
      },
      "md": {
        "textStyle": "semantic-heading-pullout-2xl"
      }
    }
  },
  {
    "format": "pullout",
    "size": "heading3",
    "css": {
      "base": {
        "textStyle": "semantic-heading-pullout-lg"
      },
      "lg": {
        "textStyle": "semantic-heading-pullout-2xl"
      },
      "md": {
        "textStyle": "semantic-heading-pullout-xl"
      }
    }
  },
  {
    "format": "pullout",
    "size": "heading4",
    "css": {
      "base": {
        "textStyle": "semantic-heading-pullout-md"
      },
      "md": {
        "textStyle": "semantic-heading-pullout-lg"
      },
      "lg": {
        "textStyle": "semantic-heading-pullout-xl"
      }
    }
  },
  {
    "format": "pullout",
    "size": "heading5",
    "css": {
      "base": {
        "textStyle": "semantic-heading-pullout-sm"
      },
      "md": {
        "textStyle": "semantic-heading-pullout-lg"
      }
    }
  },
  {
    "format": "pullout",
    "size": "heading6",
    "css": {
      "base": {
        "textStyle": "semantic-heading-pullout-xs"
      },
      "md": {
        "textStyle": "semantic-heading-pullout-md"
      }
    }
  }
])

const titleVariantMap = {
  "size": [
    "heading1",
    "heading2",
    "heading3",
    "heading4",
    "heading5",
    "heading6"
  ],
  "format": [
    "standard",
    "logotype",
    "pullout"
  ],
  "theme": [
    "dark",
    "light"
  ]
}

const titleVariantKeys = Object.keys(titleVariantMap)

export const title = /* @__PURE__ */ Object.assign(memo(titleFn.recipeFn), {
  __recipe__: true,
  __name__: 'title',
  __getCompoundVariantCss__: titleFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: titleVariantKeys,
  variantMap: titleVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, titleVariantKeys)
  },
  getVariantProps: titleFn.getVariantProps,
})
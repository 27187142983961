import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const gradientFn = /* @__PURE__ */ createRecipe('gradient', {
  "theme": "light"
}, [])

const gradientVariantMap = {
  "theme": [
    "light",
    "medium",
    "strong"
  ]
}

const gradientVariantKeys = Object.keys(gradientVariantMap)

export const gradient = /* @__PURE__ */ Object.assign(memo(gradientFn.recipeFn), {
  __recipe__: true,
  __name__: 'gradient',
  __getCompoundVariantCss__: gradientFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: gradientVariantKeys,
  variantMap: gradientVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, gradientVariantKeys)
  },
  getVariantProps: gradientFn.getVariantProps,
})